@import url("https://fonts.googleapis.com/css?family=Poppins&display=swap");

:root {
  --background-color-start: #000215;
  --background-color-end: #000215;
  --circle-color: #000215;
  --color-hold: #13e7ffa1;
  --color-hold-border: #13e7ffa1;
  --color-breath-in: #ff00eaef;
  --color-breath-in-border: #ff00eaef;
  --color-breath-out: #7826fc;
  --color-breath-out-border: #7826fc;
  --color-text: #ffffff;
}

* {
  box-sizing: border-box;
}

/* body {
  background: radial-gradient(
    var(--background-color-start),
    var(--background-color-end)
  );
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
  margin: 0;
} */

.audio-guided-container{
    background: radial-gradient(
        var(--background-color-start),
        var(--background-color-end)
    ); 
    height: 100vh;
    width: 100vw;
    font-family: Helvetica, sans-serif;
    color: #fff;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center
}
.guided-breath-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    width: 200px;
    height: 200px;
    position: relative;
    transform: scale(1);
    box-shadow: -20px -20px 20px 50px rgba(#2F2B8C, 0.1);
    background-image: radial-gradient(#150ee700, #11cc0000 50%, #c4008233 90%);
    border-radius: 100px;
}

.guided-circle {
  background: var(--circle-color);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1.5;
}

.guided-gradient-circle {
  background: conic-gradient(
    var(--color-breath-in) 0%,
    var(--color-breath-in-border) 25%,
    var(--color-hold) 25%,
    var(--color-hold-border) 50%,
    var(--color-breath-out) 50%,
    var(--color-breath-out-border) 75%,
    var(--color-hold) 75%,
    var(--color-hold) 100%
  );
  width: 204px;
  height: 204px;
  border-radius: 50%;
  position: absolute;
  top: -2px;
  left: -2px;
  z-index: -2;
}

@keyframes color-change {
    0% { background-color: var(--color-breath-in); }
    25% { background-color: var(--color-hold); }
    50% { background-color: var(--color-breath-out); }
    75% { background-color: var(--color-hold); }
    100% { background-color: var(--color-breath-in); }
  }

.guided-pointer {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: block;
  animation: color-change 20s linear infinite;
  animation-play-state: paused;
}

/* Set the 1 loop of the breathing duration seconds in 'animation' */
.guided-pointer-container {
  position: absolute;
  top: -30px;
  left: 90px;
  width: 20px;
  height: 130px;
  animation: rotate 20s linear forwards infinite;
  animation-play-state: paused;
  transform-origin: bottom center;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes grow{
  from{
    transform: scale(1)
  }to{
    transform: scale(1.4);
  }
}

@keyframes shrink{
  from{
    transform: scale(1.4)
  }to{
    transform: scale(1);
  }
}
.guided-breath-container.grow{
  animation: grow 5s linear forwards;
}
.guided-breath-container.shrink{
  animation: shrink 5s linear forwards;
}

/* Breath In */
.flare1 {
    width: 160px;
    height: 160px;
    content: "";
    display: block;
    border-radius: 37.5px;
    background-image: radial-gradient(var(--color-breath-in-border), #00000000 60%);
    position: absolute;
    right: -37.5px;
    top: -37.5px;
    z-index: -1;
}

/* Breath Out */
.flare2 {
    width: 160px;
    height: 160px;
    content: "";
    display: block;
    border-radius: 37.5px;
    background-image: radial-gradient(var(--color-breath-out-border), #D904B500 60%);
    position: absolute;
    left: -37.5px;
    bottom: -37.5px;
    z-index: -1;
}

/* Hold (top left) */
.flare3 {
  width: 160px;
  height: 160px;
  content: "";
  display: block;
  border-radius: 37.5px;
  background-image: radial-gradient(var(--color-hold), #ffffff00 70%);
  position: absolute;
  left: -15px;
  top: -15px;
  z-index: -1;
}

/* Hold (bottom right) */
.flare4 {
  width: 160px;
  height: 160px;
  content: "";
  display: block;
  border-radius: 37.5px;
  background-image: radial-gradient(var(--color-hold), #ffffff00 70%);
  position: absolute;
  right: -15px;
  bottom: -15px;
  z-index: -1;
}

#start-message {
  font-size: 2.5vh;
  color: var(--color-text);
  text-align: center;
  z-index: 1;
  display: block;
}

#top-text {
  font-size: 3vh;
  position: absolute;
  top: 20%;
  left: 50%;
  color: var(--color-text);
  transform: translate(-50%, -80%);
  z-index: 1;
}

#text {
  font-size: 5vh;
  color: var(--color-text);
  text-align: center;
  z-index: 1;
}