.after-experience-content-container{
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto auto 2pc auto;
    width: 100vw;
    overflow-x: hidden;
    z-index: 1000;
}

.major-heading-after-experience {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 36px;
    width: 70vw;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #FFFFFF;
    /* margin: auto; */
    padding: 2pc;
}

.after-experience-section-container{
    display: flex;
    flex-direction: column;
    row-gap: 1pc;
    margin: auto;
    /* margin-bottom: 2pc; */
}

.after-experience-section-one{
    font-family: 'Poppins';
    font-style: normal;
    width: 50vw;
    height: max-content;
    padding: 2pc 4pc;
    margin: auto;
    background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
    border-radius: 9.90385px;
    border-top: 2px solid rgba(225, 225, 225, 0.3);
    border-bottom: 1px solid rgba(225, 225, 225, 0.3);
    border-left: 2px solid rgba(225, 225, 225, 0.1);
    border-right: 1px solid rgba(225, 225, 225, 0.2);
    font-weight: 400;
    font-size: 18px;
    line-height: 33px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    row-gap: 0.5pc;
    justify-content: center;
    color: #FFFFFF;
    /* overflow: auto; */
}

.after-experience-section-one-heading{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 30px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
}

.after-experience-section-one-text{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    justify-content: center;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
}

.after-experience-section-two{
    font-family: 'Poppins';
    font-style: normal;
    width: 50vw;
    height: max-content;
    /* height: 130px; */
    padding: 2pc 4pc;
    margin: auto;
    background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
    border-radius: 9.90385px;
    border-top: 2px solid rgba(225, 225, 225, 0.3);
    border-bottom: 1px solid rgba(225, 225, 225, 0.3);
    border-left: 2px solid rgba(225, 225, 225, 0.1);
    border-right: 1px solid rgba(225, 225, 225, 0.2);
    font-weight: 400;
    font-size: 18px;
    line-height: 33px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    row-gap: 0.5pc;
    justify-content: center;
    color: #FFFFFF;
}

.app-download-icon-container{
    display: flex;
    flex-direction: row;
    column-gap: 0.5pc;
}

.app-download-icon{
    width: 200px;
    height: 60px;
}

.form-iframe{
    width: 550px;
    height: 600px;
}

@media screen and (max-width: 1024px) {
    .after-experience-section-one{
        width: 70vw;
    }

    .after-experience-section-two{
        width: 70vw;
    }
}

@media screen and (max-width: 786px) {
    .form-iframe{
        width: 480px;
    }
}

@media screen and (max-width: 640px) {
    .after-experience-section-one{
        width: 80vw;
    }

    .after-experience-section-two{
        width: 80vw;
    }
    .form-iframe{
        width: 420px;
    }
    .app-download-icon{
        width: 180px;
        height: 55px;
    }
}

@media screen and (max-width: 480px) {
    .after-experience-section-one{
        width: 85vw;
    }

    .after-experience-section-two{
        width: 85vw;
    }

    .form-iframe{
        width: 360px;
    }

    .major-heading-after-experience{
        font-size: 25px;
        line-height: 28px;
        width: 90vw;
    }

    .after-experience-section-one{
        padding: 2pc 2pc;
    }

    .after-experience-section-one-heading{
        font-size: 18px;
        line-height: 22px;
    }

    .after-experience-section-one-text{
        font-size: 12px;
        line-height: 15px;
    }

    .after-experience-section-two{
        font-size: 15px;
        line-height: 29px;
    }

    .app-download-icon{
        width: 130px;
        height: 40px;
    }

    .next-button-contact{
        width: 170px;
        font-size: 15px;
    }
}

@media screen and (max-width: 375px) {
    .form-iframe{
        width: 300px;
    }

    .app-download-icon{
        width: 110px;
        height: 35px;
    }
    
}