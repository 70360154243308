.review-container {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.review-background-img {
    position: absolute;
    width: 120vw;
    height: 120vh;
    filter: blur(40px);
}

.review-content-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    backdrop-filter: blur(40px);
    overflow-x: hidden;
    z-index: 1000;
}

.major-heading-review {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 36px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    margin: auto;
    padding: 2pc;
}

.major-heading-review-notcompleted {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 36px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    margin: auto auto 0 auto;
    padding: 2pc;
    width: 40%;
}

.subheading1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 27px;
    line-height: 40px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #FFFFFF;
    width: 800px;
    /* margin-top: 2%; */
    margin: auto;
    /* padding: 1pc */
}

.subheading2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    /* margin-top: 3%; */
    margin: auto;
    padding: 1pc;
    width: 40%;
}

.subheading2-notcompleted {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    margin: 0 auto auto auto;
    padding: 1pc;
    width: 40%;
}

.subheading3 {
    margin-top: 1%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 33px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    width: 700px;
    margin: auto;
    padding: 1pc;
}

.review-rating-row {
    /* margin-top: 2%; */
    margin: auto;
    display: flex;
    flex-direction: row;
    column-gap: 5%;
    justify-content: center;
}

.rating-box {
    font-family: 'Poppins';
    font-style: normal;
    width: 180px;
    height: 180px;
    background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
    border-radius: 9.90385px;
    border-top: 2px solid rgba(225, 225, 225, 0.3);
    border-bottom: 1px solid rgba(225, 225, 225, 0.3);
    border-left: 2px solid rgba(225, 225, 225, 0.1);
    border-right: 1px solid rgba(225, 225, 225, 0.2);
    font-weight: 700;
    font-size: 22px;
    line-height: 120%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    row-gap: 0.5pc;
    justify-content: center;
    color: #FFFFFF;
    transition: all .2s ease-in-out;
    cursor: pointer;
}

.rating-box img {
    width: 110px;
    height: 110px;
}

.rating-box:hover{
    background-color: white;
    color: black;
}

.rating-box:hover img{
    filter: invert(1);
}

.rating-box-active{
    background-color: white;
    color: black;
    font-family: 'Poppins';
    font-style: normal;
    width: 180px;
    height: 180px;
    border-radius: 9.90385px;
    font-weight: 700;
    font-size: 22px;
    line-height: 120%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    row-gap: 0.5pc;
    justify-content: center;
    transition: all .2s ease-in-out;
    cursor: pointer;
}

.rating-box-active img{
    filter: invert(1);
    width: 110px;
    height: 110px;
}

.review-button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 5%;
    margin-bottom: 2%;
    margin-top: 2pc;
}

.continue-button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 2pc;
    column-gap: 5%;
    margin-bottom: 2pc;
}

.no-click{
    pointer-events: none;
}

.restart-button {
    width: 300px;
    height: 60px;
    border: 4px solid #FFFFFF;
    background-color: transparent;
    border-radius: 50px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 120%;
    height: 53px;
    /* filter: drop-shadow(2px 8px 24px black); */
    /* or 36px */

    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #FFFFFF;
    cursor: pointer;
}

.restart-button:hover {
    opacity: 0.8;
}

.restart-button:disabled {
    opacity: 0.5;
}

.next-button-review {
    background: #FFFFFF;
    border-radius: 50px;
    border: 0px;
    width: 300px;
    /* flex-basis: 100%; */
    height: 60px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 120%;
    height: 53px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: black;
    cursor: pointer;
}

.next-button-review:hover {
    opacity: 0.8;
}

.next-button-review:disabled {
    opacity: 0.5;
}

.checkmark-review {
    width: 40px;
    height: 40px;
    margin-top: 2pc;
    z-index: 20;
}

.shadow-top-review {
    position: absolute;
    width: 120%;
    z-index: 0;
    height: 35%;
    align-self: center;
    top: -40px;
    filter: drop-shadow(0px 0px 0 black);
    pointer-events: none;
}
@supports (not(-webkit-touch-callout: none)) and (not(translate:none)) {

    .rating-box, .rating-box-active{
        margin-right: 0.5pc;
        margin-left: 0.5pc;
    }

    .rating-box img{
        margin-bottom: 0.5pc;
    }


    .restart-button, .next-button-review{
        margin-left: 0.5pc;
        margin-right: 0.5pc;
    }
  }


@media screen and (max-width: 1024px) {
    .box-row-emojis-review {
        width: 90%;
        column-gap: 5px !important;
    }

    .subheading1 {
        width: 90%;
    }

    .subheading3 {
        width: 70%;
    }

    .major-heading-review-notcompleted {
        width: 80%;
    }

    .subheading2-notcompleted {
        width: 80%;
    }
}

@media screen and (max-width: 640px) {
    .checkmark-review {
        margin-top: 1pc;
    }

    .major-heading-review,
    .major-heading-review-notcompleted {
        font-size: 35px;
        line-height: 30px;
    }

    .review-rating-row {
        width: 90%;
        /* height: 25%; */
        column-gap: 10px !important;
    }

    .rating-box, .rating-box-active{
        width: 150px;
        height: 150px;
        font-size: 18px;
    }

    .rating-box img {
        width: 90px;
        height: 90px;
    }

    .rating-box-active img{
        width: 90px;
        height: 90px;
    }

   .continue-button-container {
        margin-top: 10%;
    }

    .restart-button {
        width: 200px;
        font-size: 16px;
    }

    .next-button-review {
        width: 200px;
        font-size: 16px;
    }

    .subheading1 {
        width: 90%;
        /* font-size: 15px; */
    }

    .subheading2,
    .subheading2-notcompleted {
        font-size: 20px;
    }

    .subheading3 {
        width: 70%;
        font-size: 15px;
    }
}

@media screen and (max-width: 480px) {

    .review-button-container, .continue-button-container {
        margin-bottom: 20%;
    }

    .restart-button {
        width: 150px;
        font-size: 12px;
    }

    .next-button-review {
        width: 150px;
        font-size: 12px;
    }

    .major-heading-review,
    .major-heading-review-notcompleted {
        font-size: 27px;
        line-height: 30px;
    }

    .subheading1 {
        font-size: 22px;
        line-height: 25px;
    }

    .subheading2,
    .subheading2-notcompleted {
        font-size: 17px;
        line-height: 20px;
        width: 90%;
    }

    .major-heading-review,
    .subheading1,
    .subheading2 {
        padding: 0 1pc;
    }

    .rating-box, .rating-box-active{
        width: 120px;
        height: 120px;
        font-size: 15px;
    }

    .rating-box img {
        width: 70px;
        height: 70px;
    }

    .rating-box-active img{
        width: 70px;
        height: 70px;
    }

    .checkmark-review {
        width: 30px;
        height: 30px;
        margin-top: 0;
        padding-top: 0;
    }
}

@media screen and (max-width: 330px) {
    .restart-button {
        width: 130px;
    }

    .next-button-review {
        width: 130px;
    }
}

@media screen and (max-width: 300px) {

    .restart-button {
        width: 100px;
    }

    .next-button-review {
        width: 100px;
    }
}