.choose-feeling-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 2%;
}

.major-heading-feeling {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 36px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    margin: auto;
}

.box-row-emojis {
    display: flex;
    flex-direction: row;
    column-gap: 5%;
    margin: 2pc auto auto auto;
    padding: 2pc;
    border: 4px solid rgba(255, 255, 255, 0.6);
    border-radius: 50px;
    /* height: 0; */
}

.box-row-selection {
    margin-top: 5%;
    display: flex;
    flex-direction: row;
    column-gap: 5%;
    margin: auto;
    padding: 1pc;
}

.feeling-box {
    background: linear-gradient(86.16deg, rgba(255, 255, 255, 0.2) 11.14%, rgba(255, 255, 255, 0.035) 113.29%);
    box-shadow: 0px 1.1966px 29.915px rgba(69, 42, 124, 0.1), inset 10px 10px 29px rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(35px);
    border-radius: 16px;
    width: 286px;
    height: 122px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 20px;
}

.feeling-box-heading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 14px;
    margin-top: 2pc;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
}

.feeling-box-subheading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 25px;
    margin-top: 0.5pc;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    text-transform: capitalize;
}

.emoji-box {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 14px;
    /* or 56% */

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 150px;

    color: #FFFFFF;
    transition: all .2s ease-in-out;
}


.emoji-box-active {
    font-family: 'Poppins';
    font-style: normal;
    font-size: 20px;
    line-height: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    row-gap: 30px;
    width: 150px;
    color: #FFFFFF;
    cursor: pointer;
    transform: scale(1.2);
    font-weight: 700;
}

.button-container {
    margin: 1pc auto auto auto;
}

.experience-instruction {
    display: flex;
    flex-direction: row;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    /* or 43px */
    color: #FFFFFF;
    align-items: center;
    column-gap: 2pc;
    margin: 2pc auto auto auto;
    width: 65vw;
    justify-content: center;
}

.hidden-text {
    position: absolute;
    opacity: 0;
}

.headphone-icon {
    width: 50px;
    height: 50px;
}

.placeholder {
    padding: 30px;
}

@supports (not(-webkit-touch-callout: none)) and (not(translate:none)) {

    .headphone-icon {
        margin-right: 1pc;
    }
}

@media screen and (max-width: 1024px) {
    .major-heading-feeling {
        font-size: 30px;
        line-height: 30px;
    }

    .emoji-box {
        flex-basis: 100%;
        font-size: 18px;
        height: 180px;
    }

    .emoji-box img {
        flex-basis: 0%;
    }
}

@media screen and (max-width: 768px) {
    .box-row-emojis {
        padding: 0;
        column-gap: 0;
    }

    .emoji-box {
        flex-basis: 100%;
        font-size: 18px;
        height: 220px;
    }
}

@media screen and (max-width: 640px) {
    .major-heading-feeling {
        font-size: 30px;
        line-height: 30px;
        padding: 2% 4%;
    }

    .choose-feeling-container {
        margin-bottom: 20%;
    }

    .box-row-emojis {
        padding: 0 2pc;
        /* column-gap: 2pc; */
        width: 60%;
    }

    .emoji-box {
        flex-basis: 100%;
        font-size: 10px;
        width: auto;
        /* width: max-content !important; */
    }

    .emoji-box img {
        flex-basis: 0%;
        height: 50px !important;
    }

    .experience-instruction {
        font-size: 15px;
        column-gap: 1pc;
        /* width: 70vw; */
    }

    .feeling-box-subheading {
        font-size: 15px;
    }

    .next-button,
    .next-button-contact {
        width: 250px;
    }

    .back,
    .close,
    .logout {
        font-size: 15px !important;
    }

    .back img,
    .close img {
        width: 20px
    }
}

@media screen and (max-width: 480px) {

    .box-row-emojis {
        padding: 0 1pc;
        /* column-gap: 1pc; */
        width: 80%;
    }

    .emoji-box {
        flex-basis: 100%;
        font-size: 10px;
        width: auto;
        /* width: max-content !important; */
    }

    .emoji-box img {
        flex-basis: 0%;
        height: 40px !important;
    }

    .headphone-icon {
        height: 40px;
        width: 40px;
    }

    .experience-instruction {
        padding: 0 1pc;
        font-size: 14px;
        width: 80vw;
    }
}

@media screen and (max-width: 330px) {

    .back,
    .placeholder {
        column-gap: 5px;
        padding: 30px 0px 30px 10px;
    }

    .close {
        column-gap: 5px;
        padding: 30px 10px 30px 0px;
    }

    .box-row-emojis {
        padding: 0 1pc;
        /* column-gap: 1pc; */
    }

    .emoji-box {
        flex-basis: 100%;
        font-size: 10px;
    }

    .emoji-box img {
        flex-basis: 0%;
        height: 30px !important;
    }

    .feeling-box-heading {
        font-size: 12px;
    }

    .feeling-box-subheading {
        font-size: 15px;
    }
}