html, body{
    overflow: hidden;
    background: #000;
    padding: 0px;
    margin: 0px;
  }
  
  /* #info{
    position: absolute;
    top: 0px;
    left: 0px;
    color: #fff;
    background: rgba(100,100,100,0.5);
    padding: 4px 10px;
    margin: 5px;
    border-radius: 4px;
    font-size: 18px;
  } */
  .dg.ac{
    display: none !important;
  }