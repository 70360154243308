@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');

.login-content-container {
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 2pc;
}

.subtitle {
    font-family: "Poppins";
    font-size: 15px;
    color: white;
}

.login-heading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 28px;
    display: flex;
    flex-basis: 10%;
    align-items: flex-end;
    text-align: center;
    color: #FFFFFF;
    padding-bottom: 0.5pc;
    column-gap: 15px;
    padding-top: 1pc;
    margin: auto;
}

.signup-heading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    display: flex;
    flex-basis: 10%;
    align-items: flex-end;
    text-align: center;
    color: #FFFFFF;
    padding-bottom: 0.5pc;
    column-gap: 15px;
    padding-top: 1pc;
    margin: auto;
}

.subscribe-heading {
    font-family: 'Poppins';
    font-style: normal;
    min-height: 40px;
    font-weight: 700;
    font-size: 29px;
    line-height: 28px;
    display: flex;
    flex-basis: 10%;
    align-items: flex-end;
    text-align: center;
    color: #FFFFFF;
    /* padding-bottom: 0.5pc; */
    column-gap: 15px;
    margin: auto;
}

.manage-heading {
    font-family: 'Poppins';
    font-style: normal;
    min-height: 40px;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    display: flex;
    text-align: left;
    color: #FFFFFF;
    padding-bottom: 0.5pc;
    column-gap: 15px;
    width: 90%;
    /* margin: auto; */
    /* align-self: flex-start; */
}
.subscribe-content-box {
    color: #FFF;
    text-align: center;
    font-size: 20px;
    font-family: Poppins;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    width: 50%;
    align-items: center;
    border-radius: 9.904px;
    padding: 30px;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%);
    backdrop-filter: blur(25.997602462768555px);
}

.manage-content-box {
    color: #FFF;
    text-align: center;
    font-size: 20px;
    font-family: Poppins;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: 50%;
    align-items: center;
    border-radius: 9.904px;
    padding: 30px;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%);
    backdrop-filter: blur(25.997602462768555px);
}


.store-badge-container {
    display: flex;
    column-gap: 30px;
    align-items: center;
    justify-content: center;
}

.storeBadgeImage {
    width: 200px;
}

input {
    background-color: transparent;
    border: 1px solid #FFFFFF;
    border-radius: 16px;
    height: 58px;
    width: 100%;
    font-family: 'Poppins';
    font-style: normal;
    color: #FFFFFF;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    display: flex;
    padding-left: 1pc;
}

input:hover,
input:focus {
    border: 3px solid #FFFFFF;
}

input:focus-visible {
    outline: none;
}

.input-container {
    margin: auto;
    margin-top: 1pc;
    width: 400px;
    display: flex;
    flex-direction: column;
    row-gap: 1pc;
    align-items: center;
}

.input-field {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 1pc;
    position: relative;
}

.input-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 120%;
    /* identical to box height, or 34px */
    display: flex;
    align-items: center;

    color: #FFFFFF;
}

.text-danger {
    width: 100%;
    /* height: 40px; */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    /* or 24px */
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #AD0015;
    background-color: white;
}

.password-toggle {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 10px;
    bottom: 20px;
    cursor: pointer;
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
}

.forgot-password {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22.5px;
    display: flex;
    align-items: center;
    text-align: center;
    text-decoration-line: underline;
    color: #FFFFFF;
    cursor: pointer;
    align-self: flex-end;
}

.info-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-top: 1pc;
    row-gap: 1pc;
}

.sign-up-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 120%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 60%;
    color: #FFFFFF;
    /* margin: auto; */
    padding-left: 2pc;
    padding-right: 2pc;
}

.terms-of-service {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 160%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    margin: auto;
    padding-left: 2pc;
    padding-right: 2pc;
}

@media screen and (max-width: 550px) {
    .input-container {
        margin: auto;
        margin-top: 1pc;
        width: 300px;
        row-gap: 1pc;
    }
    .manage-content-box{
        width: 90%;
    }
    
    .store-badge-container {
        /* width: 30%; */
        flex-direction: column;
    }

    input {
        height: 48px;
        font-size: 15px;
    }

    .input-title {
        font-size: 15px;
    }

    .forgot-password {
        font-size: 12px;
    }

    .password-toggle {
        bottom: 15px;
    }

    .sign-up-text {
        font-size: 12px;
        width: 80%;
    }

    .terms-of-service {
        font-size: 10px;
    }
    .subscribe-content-box {
        width: 90%;
    }
}

@media screen and (max-width: 375px) {
    .input-container {
        margin: auto;
        margin-top: 1pc;
        width: 250px;
        row-gap: 1pc;
    }
}
.buy-button{
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    border-radius: 100px;
    background: #452A7C;
    padding: 11px 15px;
    font-size: 16px;
    
}

.buy-buttons-container{
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    

}

.ad-container{
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: smaller;
}
.unlock-tag{
    background: linear-gradient(339deg, #D8506C 0%, #9069ED 100%);
    border-radius: 6px 6px 0px 0px;
    padding: 5px 10px;
    font-weight: 500;

    margin-left: -1px;
}
.features-list {
    background-color: #FFF;
    width: 100%;
    color: #000;
    text-align: left;
    border-radius: 0px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding: 20px;
    position: relative;
  }
  
  .features-list::before {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    z-index: -1;
    border-radius: inherit;
    background-image: linear-gradient(rgba(160, 99, 208, 1), rgba(216, 80, 108, 1));
    pointer-events: none; /* This line ensures the pseudo-element doesn't interfere with clicks or hover events */
  }

  .cancel-box {
    background-color: #FFF;
    width: 90%;
    color: #000;
    text-align: left;
    border-radius: 20px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    padding: 20px;
    padding-bottom: 5px;
    position: relative;
  }

  .subscribe-tag{
    color: #757575;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .cancel-tag{
    color: black;
    cursor: pointer;
    text-decoration: underline;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 20px;
  }
  
  
.feature-line{
    display: flex;
    align-items: center ;
    column-gap: 10px;
    font-size: smaller;
}

.help-section{
    margin-top:20px ;
    text-align: center;
    color:white;
    font-family:Poppins;
    font-size:14px;

}


.checkmark__circle{stroke-dasharray: 166;stroke-dashoffset: 166;stroke-width: 2;stroke-miterlimit: 10;stroke: #7ac142;fill: none;animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards}
.checkmark{width: 56px;height: 56px;border-radius: 50%;display: block;stroke-width: 2;stroke: #fff;stroke-miterlimit: 10;margin: 10% auto;box-shadow: inset 0px 0px 0px #7ac142;animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both}
.checkmark__check{transform-origin: 50% 50%;stroke-dasharray: 48;stroke-dashoffset: 48;animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards}@keyframes stroke{100%{stroke-dashoffset: 0}}@keyframes scale{0%, 100%{transform: none}50%{transform: scale3d(1.1, 1.1, 1)}}@keyframes fill{100%{box-shadow: inset 0px 0px 0px 30px #7ac142}}

.popup-success-container{
    display: flex;
    flex-direction: column;
    font-family: 'Poppins';
}


.coupon-entry{
    width:65%
}

.coupon-entry input{
    border: 2px solid #E0E0E0;
    border-radius: 8px;
    height: auto;
    color: #424770 !important;
    font-size: 16px;
    padding: 10px;

}


.coupon-entry ::placeholder{
    color: #424770;
    opacity: 50%;
    font-weight: 500;
}

.apply-button{
    border: 1px solid #350879;
    color: #350879;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    width: 35%;
    font-family:'Poppins';
    border-radius: 50px;
    text-align: center;
}

.couponerror{
    font-size: 12px;
    font-family: 'Poppins';
    width: 85%;
    color:#E00000
}

.couponsuccess{
    font-size: 12px;
    font-family: 'Poppins';
    width: 85%;
    color: #039800;
}