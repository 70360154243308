.react-slider-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}  

.slider-value{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 33px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    text-align: center;
    margin: 2% 0 auto auto;
    color: #000000;
    width: 100px;
}

.slider-value-desc{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #5F5F5F;
}
  
.customSlider {
    /* max width of your slider */
    max-width: 350px;
    order: 1;
    flex-grow: 1;
    /* Optional: Only If you want to center your slider in the page */
    margin: 5% auto;
  }
  
  .customSlider-track {
    /* Top value to align your track to the center of your thumb */
    top: 8px;
    /* thickness of the track */
    height: 9px;
    border-radius: 20px;
   /* default color of your track */
    background: #D9D9D9;
  }
  
  .customSlider-track.customSlider-track-0 {
    /* color of the track before the thumb */
    background: #000000;
  }

  .customSlider-thumb {
    cursor: pointer;
    pointer-events: all;
    /*color for the thumb */
    background: #000000;
    /* shape of the thumb: circle */
    width: 25px;
    height: 25px;
    border-radius: 100%;
    /* remove default outline when selected */
    outline: none;
  }

  /* Add a shadow around the thumb when it is focused/selected */
  .customSlider-thumb.active {
    box-shadow: 0 0 0 8px #0000003a;
  }

  @media screen and (max-width: 640px){
    .react-slider-container{
      width: 80%;
      column-gap: 2%;
    }
    .slider-value{
      font-size: 18px;
    }
    .slider-value-desc{
      font-size: 16px;
    }
  }

  @media screen and (max-width: 480px){
    .slider-value{
      font-size: 16px;
      margin: 0;
      flex-direction: row;
      column-gap: 1pc;
    }
    .slider-value-desc{
      font-size: 16px;
      /* margin: 0 0 auto auto; */
    }
    .customSlider-track{
      height: 8px;
      top: 8px;
    }
    .customSlider-thumb{
      width: 25px;
      height: 25px;
    }
    .react-slider-container{
      flex-direction: column;
      height: 60px;
    }
    .customSlider{
      margin: 0;
    }
  }

  @media screen and (max-width: 320px){
    .slider-value{
      font-size: 15px;
      margin: -4px 0 auto auto;
    }
    .customSlider-track{
      height: 5px;
      top: 8px;
    }
    .customSlider-thumb{
      width: 20px;
      height: 20px;
    }
  }

  @supports (not(-webkit-touch-callout: none)) and (not(translate:none)){

    .react-slider-container{
        margin-top: 2pc;
    }
  }