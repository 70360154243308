.timer{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    display:  flex;
    align-items: center;
    text-align: center;
    width: max-content;
    white-space: nowrap;
}

@media screen and (max-width: 640px){
    .timer{
        font-size:15px
    }
}

@media screen and (max-width: 480px){
    .timer{
        font-size:12px
    }
}
