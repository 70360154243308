html, body {
    margin: 0; 
    padding: 0;
    height: 100%; 
    background-color: black;
    overflow: hidden;
    /* If you want to implement it in very old browser-versions */
    -webkit-user-select: none; /* Chrome/Safari */ 
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+ */
  
    /* The rule below is not implemented in browsers yet */
    -o-user-select: none;
  
    /* The rule below is implemented in most browsers by now */
    user-select: none;
  
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
  }
  
  .background {
    background: -webkit-linear-gradient(top, black, #0c193b);
    /* position: absolute; */
    height: 100vh;
    width: 100vw;
    top: 0px;
    left: 0px;
    overflow: hidden;
  }
  
  .moon-text {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 100;
    line-height: 1.5;
    letter-spacing: 4px;
    text-transform: uppercase;
    font-family: 'Josefin Sans';
    text-shadow: 0 0 10px white;
    font-size: 13px;
    color: white;
    transition: color .3s ease-in-out;
  }
  .nep-highlight {
    color: aquamarine;
    font-size: 20px;
    text-shadow: 0 0 10px aquamarine;
  }
  .text-hover {
    transition: color .2s ease-in-out;
  }
  .text-hover:hover {
    text-shadow: 0 0 10px gray;
    color: gray;
  }
  .clean-link {
    cursor: pointer;
    color: white;
    text-decoration: none;
  }
  
  .instructions {
    pointer-events: none;
    position: absolute;
    bottom: 20px;
    left: 50%;
    z-index: 100;
    transform: translate(-50%);
    letter-spacing: 4px;
    text-transform: uppercase;
    font-family: 'Josefin Sans';
    text-shadow: 0 0 50px white;
    font-size: 1vw;
    color: white;
  }
  
  .moonFlare {
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0px;
  left: 0px;
  overflow: hidden;
  }