/* *{
    margin: 0;
    padding: 0;
    overflow:hidden;
  } */
  
  /* body {
    margin: 0 !important;
    padding: 0 !important;
    background:#333; 
  } */
  
  #reset {
    position: absolute;
    left:10%;
    top:10px;
    background: #444;
    border: 1px solid #555;
    color: #888;
    padding: 6px 10px;
    cursor: pointer;
    opacity: 0.8;
  }
  
  #c {
    width: 100vw;
    height: 100vh;
    /* position:fixed; */
    background:#333; 
    left:0;
    top:0;
  }
  
  /* p {
    font-family:Arial, sans-serif;
    color:#666;
    text-align:justify;
    font-size: 16px;
    margin:10px;
  } */
  
  /* a {
    font-family:sans-serif;
    color:#444;
    text-decoration:none;
    font-size: 20px;
  } */
  
  #site {
    float:left;
    color: #38a;
  }
  
  #close {
    float:right;
    margin: 10px;
  }