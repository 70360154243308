
@import url("./reset.min.css");

.purple_wrap,
.navy_wrapper,
.blue_wrapper,
.green_wrapper,
.orange_wrapper,
.red_wrapper {
  overflow: hidden;
  position: relative;
  height: 100vh;
  width: 100vw;
}

canvas {
  width: 100%;
  height: 100%;
}

.purple_wrap {
  background-image: linear-gradient(-206deg, #B275FE 0%, #8ED9F9 100%);
}
.navy_wrapper {
  background-image: linear-gradient(-206deg, #8f58e4 0%, #b646be 100%)
}
.blue_wrapper{
  background-image: linear-gradient(-206deg, #C5E6FA 0%, #2891E2 100%)
}
.green_wrapper{
  background-image: linear-gradient(-206deg, #3B9374 0%, #B1D28B 100%);
}
.orange_wrapper{
  background-image: linear-gradient(-206deg, #F6722B 10%, #F0D105 100%)
}
.red_wrapper{
  background-image: linear-gradient(-206deg, #8B56E0 0%, #E3AAFF 100%)
}
