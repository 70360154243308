@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');

.experience-container {
    height: 100vh;
    width: 100vw;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: center;
    background-position: center;
}

.get-info {
    align-self: flex-start;
    cursor: pointer;
    padding: 30px;
    z-index: 1000;
    margin-right: auto;
    /* filter: drop-shadow(2px 4px 6px black); */
}

.get-info-mobile {
    align-self: flex-start;
    cursor: pointer;
    padding: 30px;
    z-index: 1000;
    margin-right: auto;
}

.get-info img {
    height: 30px;
    width: 30px;
    /* position: relative; */
}

.get-info-mobile img {
    height: 30px;
}

.get-info:hover {
    opacity: 0.8;
}

.icon-header {
    display: flex;
    flex-direction: row;
    width: 100%;
    position: absolute;
    top: 0;
}

.close {
    align-self: flex-end;
    font-family: 'Poppins';
    font-style: normal;
    color: #ffffff;
    font-weight: 500;
    font-size: 25px;
    line-height: 37px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    column-gap: 10px;
    padding: 30px;
    z-index: 20;
    margin-left: auto;
    /* filter: drop-shadow(2px 4px 6px black); */
}

.close-breath {
    align-self: end;
    font-family: 'Poppins';
    font-style: normal;
    color: #ffffff;
    font-weight: 500;
    font-size: 25px;
    line-height: 37px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    column-gap: 10px;
    padding: 30px;
    z-index: 200;
    margin-left: auto;
    position: absolute;
    top: 0;
    /* filter: drop-shadow(2px 4px 6px black); */
}

.close:hover {
    opacity: 0.8;
}

.close-breath:hover {
    opacity: 0.8;
}

#control-height {
    height: 100vh;
    width: 0;
    position: absolute;
}

.trackplayer,
.trackplayer-fullscreen {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    position: absolute;
    z-index: 300;
    bottom: 20px;
    column-gap: 10%;
}

.track-controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 10%;
}

.track-controls-randomize {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    column-gap: 10%;
}

.track-controls-not-visible {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    display: none;
}

.play {
    cursor: pointer;
    width: 60px;
    height: 60px;
    /* filter: drop-shadow(2px 4px 6px black); */
}

.visual-guided-play {
    height: 60px;
}

.volume {
    cursor: pointer;
    width: 60px;
    height: 60px;
}

.play:hover {
    opacity: 0.7;
}

.no-click {
    pointer-events: none;
}

.touchpad {
    position: absolute;
    z-index: 200;
    height: 80%;
    width: 35%;
}

.touchpad canvas {
    border-radius: 20px;
}

.shadow-top {
    position: absolute;
    width: 100%;
    z-index: 10;
    height: 35%;
    align-self: center;
    top: 0px;
    filter: drop-shadow(0px 0px 0 black);
    pointer-events: none;
}

.shadow-bottom {
    position: absolute;
    width: 100%;
    z-index: 10;
    height: 40%;
    align-self: center;
    bottom: -20px;
    filter: drop-shadow(0px 0px 0 black);
    pointer-events: none;
}

.overlay-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-self: center;
    row-gap: 5%;
    background: #FFFFFF;
    color: #000000;
    border-radius: 45px;
    width: 500px;
    height: 400px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    right: 50%;
    transform: translate(50%, -50vh);
}

.start-exp-overlay-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-self: center;
    row-gap: 5%;
    background: #FFFFFF;
    color: #000000;
    border-radius: 45px;
    width: 500px;
    height: 280px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    right: 50%;
    transform: translate(50%, -50vh);
}

.overlay-heading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 45px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
}

.popup-heading,
.popup-heading-volume {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 45px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
}

.overlay-instruction {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
}

.popup-instruction,
.popup-instruction-volume {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
}

.popup-instruction-close {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #000000;
    width: 70%;
}

.popup-instruction-payment {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 30px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #000000;
    width: 80%;
}


.okay-button {
    width: fit-content;
    background-color: black;
    border: 4px solid black;
    border-radius: 50px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 130%;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 7px;
    padding-bottom: 7px;
    height: fit-content;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #FFFFFF;
    cursor: pointer;
}

.subscribe-button {
    width: 90%;
    background-color: #350879;
    border: none;
    border-radius: 50px;
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 16px;
    padding: 15px 10px;
    height: fit-content;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #FFFFFF;
    cursor: pointer;
}


.okay-button:hover {
    opacity: 0.8;
}

.binaural-popup-heading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 45px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
    padding-left: 1pc;
    padding-right: 1pc;
}

.binaural-popup-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-self: center;
    row-gap: 5%;
    background: #FFFFFF;
    color: #000000;
    border-radius: 21px;
    width: 70vw;
    height: auto;
    /* padding: 0 2pc 2pc 2pc; */
    padding-bottom: 1pc;
    align-items: center;
    z-index: 1000;
    max-height: 80vh;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.binaural-popup-content {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 80%;
    color: #000000;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 1pc;
    outline: none;
}

.binaural-popup-content::-webkit-scrollbar-thumb {
    background-color: #000000;
    cursor: pointer;
}

.binaural-popup-images {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.binaural-infographic {
    width: 300px;
    height: 220px;
}

.binaural-waves {
    width: 300px;
    height: 260px;
}

.progress-bar-container {
    width: 170px;
    height: 170px;
}

.shadow-overlay {
    height: 100vh;
    width: 100vw;
    position: absolute;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0.38));
}

.play_icon {
    width: 80px;
    height: 80px;
    position: absolute;
    left: 50px;
}

.start-exp-play-icon {
    width: 80px;
    height: 80px;
}

.popup-container,
.popup-container-close-exp {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-self: center;
    row-gap: 5%;
    background: #FFFFFF;
    color: #000000;
    border-radius: 21px;
    width: 500px;
    height: 200px;
    align-items: center;
    z-index: 1000;
}

.popup-container-close {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-self: center;
    row-gap: 5%;
    background: #FFFFFF;
    color: #000000;
    border-radius: 21px;
    width: 500px;
    height: 295px;
    align-items: center;
    z-index: 1000;
}

.popup-container-cancel {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-self: center;
    row-gap: 5%;
    background: #FFFFFF;
    color: #000000;
    border-radius: 21px;
    width: 500px;
    height: 250px;
    align-items: center;
    z-index: 1000;
    box-shadow: 0 0 4900px 4900px #00000090;

}

.popup-container-payment {
    position: absolute;
    display: flex;
    row-gap: 20px;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    background: #FFFFFF;
    color: #000000;
    border-radius: 21px;
    width: 550px;
    height: 550px;
    align-items: center;
    z-index: 1000;
    box-shadow: 0 0 4900px 4900px #00000090;
}



.card-entry {
  background-color: #FFF;
  width: 100%;
  color: #000;
  text-align: left;
  border-radius: 20px;
  display: flex;
  border-radius: 8px;
  border: 2px solid #E0E0E0;

  flex-direction: column;
  row-gap: 16px;
  padding: 10px;
  position: relative;
}


.popup-container-volume {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-self: center;
    row-gap: 3%;
    background: #FFFFFF;
    color: #000000;
    border-radius: 21px;
    width: 550px;
    /* height: 450px; */
    padding-bottom: 2pc;
    overflow: auto;
    align-items: center;
    z-index: 1000;
}

.popup-header {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding: 0.5pc 0.5pc 0 0.5pc;
}

.popup-close-icon {
    width: 30px;
    height: 30px;
    filter: invert(1);
    align-self: flex-end;
    cursor: pointer;
    pointer-events: auto;
    margin-left: auto;
}

.popup-info-icon {
    width: 30px;
    height: 30px;
    filter: invert(1);
    align-self: flex-start;
}

@supports (not(-webkit-touch-callout: none)) and (not(translate:none)) {

    .trackplayer,
    .trackplayer-fullscreen {
        margin-right: 1pc;
    }

    .play {
        margin-right: 0.5pc;
    }

    .start-exp-play-icon {
        margin-top: 0.5pc;
    }
}

@media screen and (max-width: 1024px) {
    .popup-container-cancel{
        width: 90%;

    }

    .popup-container-payment{
        width: 90%;
    }
    .binaural-popup-heading {
        font-size: 27px;
    }

    .binaural-popup-images {
        flex-direction: column;
    }

    .track-controls,
    .track-controls-randomize,
    .track-controls-not-visible {
        flex-direction: column;
    }

    /* .binaural-waves{
        width: 270px;
        height: 220px;
    }
    .binaural-infographic{
        width: 270px;
        height: 220px;
    } */
    .binaural-popup-content {
        font-size: 18px;
    }
}

@media screen and (max-width: 640px) {
    .overlay-container {
        width: 350px;
        height: 300px;
        row-gap: 3%;
    }

    .start-exp-overlay-container {
        width: 350px;
        height: 240px;
    }

    .progress-bar-container {
        width: 150px;
        height: 150px;
    }

    .overlay-heading {
        font-size: 20px;
        line-height: 30px;
    }

    .overlay-instruction {
        font-size: 15px;
        line-height: 25px;
    }

    .popup-container,
    .popup-container-close-exp {
        width: 400px;
        height: 180px;
        row-gap: 3%;
    }

    .popup-container-close {
        width: 400px;
        height: 230px;
        row-gap: 3%;
    }

    .popup-container-volume {
        width: 450px;
        transform: translate(0%, -10vh);
        /* height: 450px; */
    }

    .popup-instruction-volume {
        font-size: 18px;
    }

    .binaural-popup-heading {
        font-size: 24px;
    }

    .binaural-waves {
        width: 250px;
        height: 220px;
    }

    .binaural-infographic {
        width: 250px;
        height: 220px;
    }

    .binaural-popup-content {
        font-size: 15px;
        height: 300px;
    }

    .close-breath {
        font-size: 15px !important;
    }

    .close-breath img {
        width: 20px;
    }

    /* .get-info{
        left: 70px;
    } */

    .get-info img {
        width: 20px;
        height: 20px;
    }

    .get-info-mobile img {
        width: 20px;
        height: 20px;
    }


    .play {
        width: 40px;
        height: 40px;
    }

    .visual-guided-play {
        height: 40px;
    }

    .play_icon {
        width: 70px;
        height: 70px;
        left: 45px;
        right: auto;
    }

    .start-exp-play-icon {
        width: 60px;
        height: 60px;
    }

    .track-controls {
        column-gap: 5%;
        flex-direction: column;
    }

    .track-controls-randomize {
        column-gap: 5%;
        flex-direction: column;
    }

    .trackplayer,
    .trackplayer-fullscreen {
        column-gap: 5%;
    }

    .shadow-bottom {
        bottom: 0px !important;
    }
}

@media screen and (max-width: 480px) {
    .overlay-container {
        width: 300px;
        height: 250px;
        row-gap: 3%;
        transform: translate(50%, -50vh);
    }

    .start-exp-overlay-container {
        width: 280px;
        height: 200px;
        transform: translate(50%, -50vh);
    }

    .progress-bar-container {
        width: 130px;
        height: 130px;
    }

    .popup-container {
        width: 300px;
        height: 120px;
        row-gap: 3%;
    }

    .popup-container-close {
        width: 300px;
        height: 170px;
        row-gap: 3%;
    }

    .popup-container-close-exp {
        width: 300px;
        height: 150px;
        row-gap: 3%;
    }

    .popup-container-volume {
        width: 300px;
        /* height: 400px; */
        row-gap: 1%;
    }

    .popup-instruction-volume {
        font-size: 14px;
        width: 70%;
    }

    .okay-button {
        font-size: 15px;
        width: fit-content;
        height: 35px;
    }

    .popup-heading {
        font-size: 15px;
        line-height: 25px;
    }

    .popup-heading-volume {
        font-size: 20px;
        line-height: 30px;
    }

    .popup-instruction,
    .popup-instruction-close {
        font-size: 12px;
        line-height: 22px;
    }

    .binaural-waves {
        width: 220px;
        height: 220px;
    }

    .binaural-infographic {
        width: 220px;
        height: 220px;
    }

    .trackplayer {
        margin-bottom: 4pc;
    }

    .trackplayer-fullscreen {
        margin-bottom: 0;
    }

    .play {
        width: 30px;
        height: 30px;
    }

    .visual-guided-play {
        height: 30px;
    }

    .play_icon {
        left: 35px;
        right: auto;
    }

    .popup-close-icon,
    .popup-info-icon {
        width: 20px
    }
}

@media screen and (max-width: 320px) {
    .overlay-container {
        width: 250px;
        height: 200px;
        row-gap: 2%;
    }

    .start-exp-overlay-container {
        width: 250px;
        height: 160px;
    }

    .progress-bar-container {
        width: 100px;
        height: 100px;
    }

    .play_icon,
    .start-exp-play-icon {
        width: 50px;
        height: 50px;
    }

    .play_icon {
        left: 30px;
    }

    .start-exp-play-icon {
        width: 40px;
        height: 40px;
    }

    .overlay-heading {
        font-size: 15px;
        line-height: 25px;
    }

    .overlay-instruction {
        font-size: 12px;
        line-height: 22px;
    }

    .popup-container {
        width: 260px;
        height: 100px;
        row-gap: 2%;
    }

    .popup-container-close {
        width: 260px;
        height: 160px;
        row-gap: 2%;
    }

    .popup-container-close-exp {
        width: 260px;
        height: 140px;
        row-gap: 2%;
    }

    .okay-button {
        font-size: 12px;
        width: fit-content;
        height: 30px;
    }

    /* .popup-close-icon {
        padding: 0 5% 0 5%;
    } */

    .binaural-waves {
        width: 160px;
        height: 150px;
    }

    .binaural-infographic {
        width: 160px;
        height: 150px;
    }

    .binaural-popup-container {
        width: 80vw;
    }
}

html {
    overscroll-behavior-x: none;
}

body {
    overscroll-behavior-x: none;
}

.expiry-cvc-entry{
    display: flex;
    flex-direction: row;
    width: 90%;
    column-gap: 20px;
}

.payment-field{
    width: 90%;
    font-family: "Poppins";
    row-gap: 5px;
    display: flex;
    color: #00000090;
    font-weight: 600;
    font-size: 12px;
    flex-direction: column;
}

